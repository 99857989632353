import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, storage } from '../firebaseConfig';
import { signOut, onAuthStateChanged} from 'firebase/auth';
import { ref, uploadString, listAll, getDownloadURL, deleteObject,updateMetadata } from 'firebase/storage';
import { getFirestore, doc, getDoc, setDoc, collection, addDoc, query, where,getDocs} from 'firebase/firestore';
import NavBar from './NavBar';
import Sidebar from './Sidebar';
import subjectGroups from './LibrarySubjects';
import './Base.css';
import { FaShareAlt,FaTrash,FaPlusCircle, FaFolderOpen, FaEraser,FaEye, FaEyeSlash,FaStar, FaRegStar } from 'react-icons/fa';

interface LibraryItemType {
  question: string;
  options?: { [key: string]: string };
  answer: string;
}

const Library: React.FC = () => {
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState<string[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<string | null>(null);
  const [libraryItems, setLibraryItems] = useState<LibraryItemType[]>([]);
  const [isSubjectSelectionOpen, setIsSubjectSelectionOpen] = useState(false);
  const [isNewSubjectOpen, setIsNewSubjectOpen] = useState(false);
  const [isRemoveSubjectOpen, setIsRemoveSubjectOpen] = useState(false);
  const [newSubject, setNewSubject] = useState<string>('');
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
  const [showAnswerIndexes, setShowAnswerIndexes] = useState<number[]>([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true);
  const [shareModalOpen, setShareModalOpen] = useState(false); // State for Share Modal
  const [shareEmail, setShareEmail] = useState(''); // Email input state
  const [shareMessage, setShareMessage] = useState(''); // Message input state
  const subjectSelectionModalRef = useRef<HTMLDivElement | null>(null);

  const handleViewHomePage = () => {
    navigate('/', { state: { selectedSubject, subjects } });
  };

  const handleSelectSubject = useCallback(async (subject: string) => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', currentUser.uid);

    if (isCheckboxChecked) {
      const subjectFileName = `library/${currentUser.uid}_${subject}_qna.json`;
      const subjectRef = ref(storage, subjectFileName);
      const url = await getDownloadURL(subjectRef);

      await setDoc(userDocRef, { savedLibraryUrl: url }, { merge: true });
    }

    setSelectedSubject(subject);
    const subjectFileName = `library/${currentUser.uid}_${subject}_qna.json`;
    const subjectRef = ref(storage, subjectFileName);
    const url = await getDownloadURL(subjectRef);
    const response = await fetch(url);
    const data = await response.json();

    setLibraryItems(data);
    setIsCheckboxDisabled(false);
  }, [isCheckboxChecked]);


// Function to fetch saved library URL and pre-select subject if available
const fetchSavedLibraryUrl = useCallback(async (subjectNames: string[]) => {
  const currentUser = auth.currentUser;
  if (!currentUser) return;

  const firestore = getFirestore();
  const userDocRef = doc(firestore, 'users', currentUser.uid);
  const userDoc = await getDoc(userDocRef);

  if (userDoc.exists()) {
    const savedLibraryUrl = userDoc.data().savedLibraryUrl || '';
    if (savedLibraryUrl) {
      const subjectName = savedLibraryUrl.split('_').slice(1, -1).join('_');

      if (subjectNames.includes(subjectName)) {
        setSelectedSubject(subjectName);
        setIsCheckboxChecked(true);
        setIsCheckboxDisabled(false);
        await handleSelectSubject(subjectName);
      }
    }
  }
}, [handleSelectSubject]);

// Function to fetch subjects from Firebase Storage
const fetchSubjects = useCallback(async (userUID: string) => {
  const listRef = ref(storage, 'library/');
  const res = await listAll(listRef);

  const filteredItems = res.items.filter(item => item.name.startsWith(`${userUID}_`));
  const subjectNames = filteredItems.map(item => item.name.split('_').slice(1, -1).join('_'));

  setSubjects(subjectNames);
  await fetchSavedLibraryUrl(subjectNames);
}, [fetchSavedLibraryUrl]);

useEffect(() => {
  const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
    if (currentUser) {
      const userUID = currentUser.uid;
      
      // Fetch subjects and saved library URL
      await fetchSubjects(userUID);
    } else {
      console.warn("No user is authenticated, skipping Firebase operations.");
      setSubjects([]);
      setSelectedSubject(null);
      setLibraryItems([]);
    }
  });

  return () => unsubscribe();
}, [handleSelectSubject,fetchSubjects]);

  // Disable scrolling when the component is mounted
  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (subjectSelectionModalRef.current && !subjectSelectionModalRef.current.contains(event.target as Node)) {
      setIsSubjectSelectionOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isSubjectSelectionOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSubjectSelectionOpen, handleClickOutside]);

  useEffect(() => {
    const fetchSubjects = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return; // Ensure that a user is logged in
  
      const userUID = currentUser.uid; // Get current user UID
      const listRef = ref(storage, 'library/');
      const res = await listAll(listRef);
  
      // Filter files that belong to the current user (i.e., filenames start with their UID)
      const filteredItems = res.items.filter(item => item.name.startsWith(`${userUID}_`));
  
      // Extract the subject names from the filtered items
      const subjectNames = filteredItems.map(item => item.name.split('_').slice(1, -1).join('_'));
  
      setSubjects(subjectNames); // Set the filtered subjects
  
      await fetchSavedLibraryUrl(subjectNames); // Fetch saved library URL and check if any subject is pre-selected
    };
  
    const fetchSavedLibraryUrl = async (subjectNames: string[]) => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;
  
      const firestore = getFirestore();
      const userDocRef = doc(firestore, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const savedLibraryUrl = userDoc.data().savedLibraryUrl || '';
        if (savedLibraryUrl) {
          // Extract the subject name from the saved URL
          const subjectName = savedLibraryUrl.split('_').slice(1, -1).join('_');
  
          // If the subject exists in the filtered subjectNames list, select it
          if (subjectNames.includes(subjectName)) {
            setSelectedSubject(subjectName);
            setIsCheckboxChecked(true);
            setIsCheckboxDisabled(false);
            await handleSelectSubject(subjectName);
          }
        }
      }
    };
  
    fetchSubjects(); // Call the fetchSubjects function
  }, [handleSelectSubject]);
  
  
  const handleAddNewSubject = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser || !newSubject || subjects.includes(newSubject)) return;
  
    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', currentUser.uid);
    const userDoc = await getDoc(userDocRef);
  
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const isStarterUser = userData?.subscriptionStatus !== true;
  
      // Check if the user is a starter user and already has 2 subjects
      if (isStarterUser && subjects.length >= 2) {
        alert('Starter users can only create up to two subjects.');
        return; // Stop adding a new subject
      }
    }
  
    const subjectFileName = `library/${currentUser.uid}_${newSubject}_qna.json`;
    const subjectRef = ref(storage, subjectFileName);
    await uploadString(subjectRef, JSON.stringify([]), 'raw', {
      contentType: 'application/json',
    });
  
    const shareWithPublic = window.confirm('Do you want to share this file with the public?');

    // After the file upload, update its metadata
    const customMetadata = {
      customMetadata: {
        UserID:currentUser.uid,
        ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
        DocumentType:'Subject',
      },
    };

    // Update the file's metadata with the custom data
    await updateMetadata(subjectRef, customMetadata);

    setSubjects([...subjects, newSubject]);
    setSelectedSubject(newSubject);
    setIsNewSubjectOpen(false);
  };
  
  const handleAddExistingSubjects = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;
  
    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', currentUser.uid);
    const userDoc = await getDoc(userDocRef);
  
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const isStarterUser = userData?.subscriptionStatus !== true;
  
      // Check if the user is a starter user and already has 2 subjects
      if (isStarterUser && subjects.length >= 2) {
        alert('Starter users can only have up to two subjects.');
        return; // Stop adding existing subjects
      }
    }
  
    const newSubjects = selectedSubjects.filter(subject => !subjects.includes(subject));
    const updatedSubjects = [...subjects, ...newSubjects];
  
    // Ensure the new total number of subjects does not exceed 2 for starter users
    if (newSubjects.length + subjects.length > 2) {
      alert('Starter users can only have up to two subjects.');
      return;
    }
  
    setSubjects(updatedSubjects);
    setSelectedSubjects([]);
  
    for (const subject of newSubjects) {
      const subjectFileName = `library/${currentUser.uid}_${subject}_qna.json`;
      const subjectRef = ref(storage, subjectFileName);
      await uploadString(subjectRef, JSON.stringify([]), 'raw', {
        contentType: 'application/json',
      });
      const shareWithPublic = window.confirm('Do you want to share this file with the public?');

      // After the file upload, update its metadata
      const customMetadata = {
        customMetadata: {
          UserID:currentUser.uid,
          ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
          DocumentType:'subject',
        },
      };
      // Update the file's metadata with the custom data
      await updateMetadata(subjectRef, customMetadata);
    }
    setIsSubjectSelectionOpen(false);
  };
  

  const handleRemoveSubjects = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser || selectedSubjects.length === 0) return;
  
    const confirmRemoval = window.confirm(`Are you sure you want to remove the selected subjects and their associated files?`);
    if (!confirmRemoval) return;
    
    // Loop through the selected subjects
    for (const subject of selectedSubjects) {
      const subjectFileName = `library/${currentUser.uid}_${subject}_qna.json`;
      const subjectRef = ref(storage, subjectFileName);
  
      try {
        // Try to get the download URL to check if the file exists
        await getDownloadURL(subjectRef);
  
        // If the file exists, proceed to delete it
        await deleteObject(subjectRef);
        console.log(`File ${subjectFileName} deleted successfully.`);
  
      } catch (error) {
        // Check if error is an instance of Firebase Storage Error
        if (error instanceof Error && (error as any).code === 'storage/object-not-found') {
          console.warn(`File ${subjectFileName} not found. Removing subject from the list.`);
        } else if (error instanceof Error) {
          console.error('Error deleting file:', error.message);
          alert('Error deleting the file. Please try again.');
          return; // Stop further execution if there's a deletion error
        } else {
          console.error('Unknown error occurred:', error);
          return;
        }
      }
    }
  
    // After checking all selected subjects, update the state to remove the subjects from the list
    const remainingSubjects = subjects.filter(subject => !selectedSubjects.includes(subject));
    setSubjects(remainingSubjects);
    setSelectedSubjects([]);
    setIsRemoveSubjectOpen(false);
  };
  
  const handleShareLibrary = () => {
    if (!selectedSubject) {
      alert('Please select a subject to share.');
      return;
    }
    setShareModalOpen(true); // Open the share modal
  };

  const handleSendShare = async () => {
    const firestore = getFirestore();
    const currentUser = auth.currentUser;
    if (!currentUser || !selectedSubject) return;

    try {
      const receiverUser = await getUserByEmail(shareEmail);
      if (!receiverUser) {
        alert('User with this email does not exist.');
        return;
      }

      const receiverUID = receiverUser.uid;
      const subjectFileName = `library/${currentUser.uid}_${selectedSubject}_qna.json`;
      const subjectRef = ref(storage, subjectFileName);
      const fileUrl = await getDownloadURL(subjectRef);

      await addDoc(collection(firestore, 'notifications'), {
        receiverId: receiverUID,
        senderId: currentUser.uid,
        fileUrl,
        fileName: selectedSubject + '.json',
        fileType: 'subject',
        message: `${currentUser.email} is sharing the subject "${selectedSubject}" with you.`,
        status: 'pending',
        createdAt: new Date(),
        isRead: false,
      });

      alert(`Sharing subject "${selectedSubject}" message sent to ${shareEmail}`);
      setShareModalOpen(false); // Close the modal
    } catch (error) {
      console.error('Error sharing subject:', error);
      alert('Failed to share the subject. Please try again.');
    }
  };

    // Function to get the user by email from Firestore
    const getUserByEmail = async (email: string) => {
      const firestore = getFirestore();
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef, where('email', '==', email));
  
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        return { uid: userDoc.id, ...userDoc.data() }; // Return UID and user data
      }
      return null; // No user found with the provided email
    };

    const handleCancelShare = () => {
      setShareModalOpen(false);
    };

  const handleDeleteItem = async (index: number) => {
    const currentUser = auth.currentUser;
    if (!currentUser || !selectedSubject) return;

    const confirmDeletion = window.confirm('Are you sure you want to delete this question?');
    if (!confirmDeletion) return;

    const updatedLibraryItems = libraryItems.filter((_, i) => i !== index);
    setLibraryItems(updatedLibraryItems);

    const subjectFileName = `library/${currentUser.uid}_${selectedSubject}_qna.json`;
    const subjectRef = ref(storage, subjectFileName);
    await uploadString(subjectRef, JSON.stringify(updatedLibraryItems));
  };

  const handleToggleAnswer = (index: number) => {
    if (showAnswerIndexes.includes(index)) {
      setShowAnswerIndexes(showAnswerIndexes.filter(i => i !== index));
    } else {
      setShowAnswerIndexes([...showAnswerIndexes, index]);
    }
  };

  const handleToggleSubjectSelection = (subject: string) => {
    if (selectedSubjects.includes(subject)) {
      setSelectedSubjects(selectedSubjects.filter(item => item !== subject));
    } else {
      setSelectedSubjects([...selectedSubjects, subject]);
    }
  };

  const handleCheckboxChange = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser || !selectedSubject) return;

    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', currentUser.uid);

    if (!isCheckboxChecked) {
      await setDoc(userDocRef, { selectedFileUrl: '' }, { merge: true });

      const subjectFileName = `library/${currentUser.uid}_${selectedSubject}_qna.json`;
      const subjectRef = ref(storage, subjectFileName);
      const url = await getDownloadURL(subjectRef);

      await setDoc(userDocRef, { savedLibraryUrl: url }, { merge: true });
    } else {
      await setDoc(userDocRef, { savedLibraryUrl: '' }, { merge: true });
    }

    setIsCheckboxChecked(!isCheckboxChecked);
  };

  return (
        <div className="content-inner">
          <h2 style={{ color: 'white'}}>Question Bank Subjects</h2>

          <div className="subject-action-buttons">
          <button title="Add New Subject" className="icon-button" onClick={() => setIsNewSubjectOpen(true)}>
            <FaPlusCircle size={24} />
          </button>

            <button title="Select Exist. Subject" className="icon-button" onClick={() => setIsSubjectSelectionOpen(true)}>
              <FaFolderOpen size={24} />
            </button>
            <button title="Remove Subject" className="library-button library-remove-button" onClick={() => setIsRemoveSubjectOpen(true)}>
            <FaTrash size={20} />
            </button>
            <button title="Share" className="library-button library-share-button" onClick={handleShareLibrary}>
              <FaShareAlt size={20} />
            </button>

            <div style={{ display: 'inline-block', marginLeft: '10px' }}>
            <button
              title="Select a Subject" 
              onClick={handleCheckboxChange}
              disabled={isCheckboxDisabled}
              className="library-checkbox"
            >
            {isCheckboxChecked ? <FaStar size={22} /> : <FaRegStar size={22}/>}
            </button>
            </div>
          </div>

          <select
            value={selectedSubject || ''}
            onChange={(e) => handleSelectSubject(e.target.value)}
          >
            <option value="" disabled>Select a subject</option>
            {subjects.sort().map((subject, index) => (
              <option key={index} value={subject}>{subject}</option>
            ))}
          </select>

          {isSubjectSelectionOpen && (
            <div className="modal-overlay">
              <div 
               ref={subjectSelectionModalRef} 
               className="modal-content swindow-modal" 
               style={{ maxHeight: '400px', overflowY: 'auto' }}
               >
                <h3 className="modal-title">Select Subjects</h3>
                <div className="subject-selection">
                  {Object.entries(subjectGroups).map(([group, groupSubjects], index) => (
                    <div key={index}>
                      <h4 className="subject-group-title">{group}</h4>
                      <ul className="swindow-list">
                        {groupSubjects.map(subject => (
                          <li key={subject} className="swindow-item">
                            <input
                              type="checkbox"
                              checked={selectedSubjects.includes(subject)}
                              onChange={() => handleToggleSubjectSelection(subject)}
                              className="swindow-checkbox"
                            />
                            <label className="swindow-checkbox-label">{subject}</label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                <div className="swindow-actions">
                  <button className="swindow-button cancel-button" onClick={() => setIsSubjectSelectionOpen(false)}>Cancel</button>
                  <button className="swindow-button add-button" onClick={handleAddExistingSubjects}>Add Selected</button>
                </div>
              </div>
            </div>
          )}

          {/* Share Modal */}
          {shareModalOpen && (
            <div className="modal-overlay">
              <div className="modal-content notes-share-modal">
                <h3 className="modal-title">Share Library Subject</h3>
                <div className="modal-row notes-share-row">
                  <label className="notes-share-label">To:</label>
                  <input
                    type="email"
                    value={shareEmail}
                    onChange={(e) => setShareEmail(e.target.value)}
                    placeholder="Enter recipient's email"
                    className="notes-share-input"
                  />
                </div>
                <div className="modal-row notes-share-row">
                  <label className="notes-share-label">Message:</label>
                  <textarea
                    value={shareMessage}
                    onChange={(e) => setShareMessage(e.target.value)}
                    placeholder="Enter your message"
                    className="notes-share-textarea"
                  />
                </div>
                <div className="modal-actions notes-share-actions">
                  <button className="cancel-button" onClick={handleCancelShare}>
                    Cancel
                  </button>
                  <button className="notes-share-send-button" onClick={handleSendShare}>
                    Send
                  </button>
                </div>
              </div>
            </div>
          )}

          {isNewSubjectOpen && (
            <div className="modal-overlay">
              <div className="modal-content swindow-modal">
                <h3 className="modal-title">Add New Subject</h3>
                <input
                  type="text"
                  value={newSubject}
                  onChange={(e) => setNewSubject(e.target.value)}
                  placeholder="Enter subject name"
                  className="swindow-input"
                />
                <div className="swindow-actions">
                  <button className="swindow-button cancel-button" onClick={() => setIsNewSubjectOpen(false)}>Cancel</button>
                  <button className="swindow-button add-button" onClick={handleAddNewSubject}>Add Subject</button>
                </div>
              </div>
            </div>
          )}

          {isRemoveSubjectOpen && (
            <div className="modal-overlay">
              <div className="modal-content swindow-modal" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <h3 className="modal-title">Remove Subjects</h3>
                <div className="subject-selection">
                  <ul className="swindow-list">
                    {subjects.map(subject => (
                      <li key={subject} className="swindow-item">
                        <input
                          type="checkbox"
                          checked={selectedSubjects.includes(subject)}
                          onChange={() => handleToggleSubjectSelection(subject)}
                          className="swindow-checkbox"
                        />
                        <label className="swindow-checkbox-label">{subject}</label>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="swindow-actions">
                  <button className="swindow-button cancel-button" onClick={() => setIsRemoveSubjectOpen(false)}>Cancel</button>
                  <button className="swindow-button remove-button" onClick={handleRemoveSubjects}>Remove Selected</button>
                </div>
              </div>
            </div>
          )}

          <div className="library-items" style={{ maxHeight: '400px', overflowY: 'auto', padding: '10px'}}>
            {libraryItems.map((item, index) => (
              <div key={index} className="library-item" style={{ marginBottom: '10px' }}>
                <div
                  className="library-card"
                  style={{ cursor: 'pointer', padding: '10px', borderRadius: '5px'}}
                >
                  <div className="front">
                    <p style={{ color: 'white' }}><strong>Question:</strong> {item.question}</p>
                    {item.options && (
                      <ul style={{ paddingLeft: '20px' }}>
                        {Object.entries(item.options).map(([key, value]) => (
                          <li key={key} style={{ color: 'white' }}><strong>{key}:</strong> {value}</li>
                        ))}
                      </ul>
                    )}
                    {showAnswerIndexes.includes(index) && (
                      <p style={{ marginTop: '10px', color: 'white' }}><strong>Answer:</strong> {item.answer}</p>
                    )}
                  </div>
                </div>

                {/* Button container with flexbox */}
                <div style={{ display: 'flex', gap: '10px', marginTop: '10px', marginLeft: '100px'}}>
                <button 
                  title={showAnswerIndexes.includes(index) ? "Hide Answer" : "See Answer"} 
                  onClick={() => handleToggleAnswer(index)} 
                  className="icon-button"
                >
                  {showAnswerIndexes.includes(index) ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                </button>
                <button title="Delete" onClick={() => handleDeleteItem(index)} className="icon-button">
                  <FaEraser size={20} /> 
                </button>
              </div>
              </div>
            ))}
          </div>
        </div>
  );
};

export default Library;
