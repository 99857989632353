import React, { useState, useEffect } from 'react';
import { getFirestore, doc,getDoc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage'; // Ensure getDownloadURL is imported here
import { auth } from '../firebaseConfig';
import NavBar from './NavBar';
import Sidebar from './Sidebar'; 
import EditProfileModal from './EditProfileModal';
import './Base.css';
import { useNavigate } from 'react-router-dom';
import { signOut,onAuthStateChanged} from 'firebase/auth';
import Auth from './Auth';

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [bio, setBio] = useState(''); // Initialize bio state
  const [pdfFilesCount, setPdfFilesCount] = useState(0); 
  const [audioFilesCount, setAudioFilesCount] = useState(0); 
  const [videoFilesCount, setVideoFilesCount] = useState(0); 
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // Disable scrolling when the component is mounted
  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  // Fetch user profile and file count whenever the user state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Fetch profile data from Firestore
        const userDocRef = doc(getFirestore(), 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFirstName(userData?.firstName || '');
          setLastName(userData?.lastName || '');
          setBio(userData?.bio || 'No bio yet.');
          setUserImage(userData?.imageUrl || '/path-to-user-image.png');
        }
        const fetchPdfFilesCount = async () => {
          const storage = getStorage();
          const listRef = ref(storage, 'uploads/');
          const res = await listAll(listRef);
    
          const userFiles = res.items.filter((item) => item.name.includes(user.uid));
          setPdfFilesCount(userFiles.length);
        };
    
        const fetchAudioFilesCount = async () => {
          const storage = getStorage();
          const listRef = ref(storage, 'recordings/');
          const res = await listAll(listRef);
    
          const userFiles = res.items.filter((item) => item.name.includes(user.uid));
          setAudioFilesCount(userFiles.length);
        };
    
        const fetchVideoFilesCount = async () => {
          const storage = getStorage();
          const listRef = ref(storage, 'videos/');
          const res = await listAll(listRef);
    
          const userFiles = res.items.filter((item) => item.name.includes(user.uid));
          setVideoFilesCount(userFiles.length);
        };
    
        fetchPdfFilesCount();
        fetchAudioFilesCount();
        fetchVideoFilesCount();

      } else {
        console.warn('User not authenticated, skipping data fetch.');
      }
    });

    // Clean up the onAuthStateChanged listener on component unmount
    return () => unsubscribe();
  }, []);


  useEffect(() => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      const fetchPdfFilesCount = async () => {
        const storage = getStorage();
        const listRef = ref(storage, 'uploads/');
        const res = await listAll(listRef);
  
        const userFiles = res.items.filter((item) => item.name.includes(currentUser.uid));
        setPdfFilesCount(userFiles.length);
      };
  
      const fetchAudioFilesCount = async () => {
        const storage = getStorage();
        const listRef = ref(storage, 'recordings/');
        const res = await listAll(listRef);
  
        const userFiles = res.items.filter((item) => item.name.includes(currentUser.uid));
        setAudioFilesCount(userFiles.length);
      };
  
      const fetchVideoFilesCount = async () => {
        const storage = getStorage();
        const listRef = ref(storage, 'videos/');
        const res = await listAll(listRef);
  
        const userFiles = res.items.filter((item) => item.name.includes(currentUser.uid));
        setVideoFilesCount(userFiles.length);
      };
  
      fetchPdfFilesCount();
      fetchAudioFilesCount();
      fetchVideoFilesCount();

  }, []);

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string, loadedBio: string) => { // Added bio
    setFirstName(loadedFirstName);
    setLastName(loadedLastName);
    setUserImage(loadedImageUrl);
    setBio(loadedBio); // Set bio
  };

  const handleEditProfile = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };

  const handleSaveProfile = async (updatedFirstName: string, updatedLastName: string, updatedBio: string, updatedImage: File | null) => {
    try {
      let imageUrl = userImage;

      if (updatedImage) {
        const imageRef = ref(getStorage(), `profileImages/${auth.currentUser?.uid}`);
        await uploadBytes(imageRef, updatedImage);
        imageUrl = await getDownloadURL(imageRef); // Correct usage of getDownloadURL
      }

      const userDocRef = doc(getFirestore(), 'users', auth.currentUser?.uid as string);
      await setDoc(userDocRef, {
        firstName: updatedFirstName,
        lastName: updatedLastName,
        bio: updatedBio,
        imageUrl: imageUrl,
      }, { merge: true });

      setFirstName(updatedFirstName);
      setLastName(updatedLastName);
      setBio(updatedBio); // Update bio
      setUserImage(imageUrl);

      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleHomeClick = async () => {
    // Your logic to handle the home click, e.g., navigate to the home page or fetch data
    navigate('/'); // Example navigation action
  };

  return (
    <div className="container">
      <NavBar 
        userImage={userImage} 
        onProfileLoad={handleProfileLoad} 
      />
      <div className="main-content">
      <Sidebar onLogout={handleLogout} onHomeClick={handleHomeClick} />
        <div className="content">
          {/* Centered Profile Content */}
          <div className="content-inner">
            <div className="profile-header">
              <div className="profile-image-container"    >
                <img src={userImage} alt={`${firstName} ${lastName}`} className="profile-image" />
              </div>
              <div className="profile-info">
                <h1>{firstName} {lastName}</h1>
                <p>{bio || 'No bio yet.'}</p> {/* Display bio */}
                <button className="edit-button" onClick={handleEditProfile}>
                  Edit profile
                </button>
              </div>
            </div>

            {/* Stats */}
            <div className="profile-stats">
            <div>
            <strong>{pdfFilesCount}</strong> PDF Files;
            </div>

            <div>
              <strong>{audioFilesCount}</strong> Audio Files;
            </div>

            <div>
              <strong>{videoFilesCount}</strong> Video Files
            </div>

            </div>

          </div>
        </div>
      </div>

      {isEditModalOpen && (
        <EditProfileModal
          firstName={firstName}
          lastName={lastName}
          userImage={userImage}
          userBio={bio} // Pass bio to modal
          onClose={handleCloseModal}
          onSave={handleSaveProfile}
        />
      )}

    </div>
  );
};

export default Profile;